import { formatInTimeZone } from "date-fns-tz";

class DateFormatter {
    defaultTimeStamp(dateTime: string): string {
        const date = new Date(dateTime);

        if (isNaN(date.getTime())) {
            return "invalid";
        }

        return formatInTimeZone(date, "Asia/Manila", "MMM dd, yyy - hh:mm:ss a");
    }

    customDateFormat(
        dateTime: string|Date,
        customerFormat: string,
        timezone: string,
    ): string {
        const date = new Date(dateTime);
        if (isNaN(date.getTime())) {
            return "invalid";
        }

        return formatInTimeZone(date, timezone, customerFormat);
    }

    formatTime( time: string|null):string {

        if(time != null){
            time = this.extractTime(time)
            if(time){
                const [hours, minutes, seconds] = time.split(':').map(Number);
        
                const date:Date = new Date();
        
                // Set the hours, minutes, and seconds
                date.setHours(hours, minutes, seconds, 0);
                return this.customDateFormat(date, 'h:mm a', "Asia/Manila")
            }
        }
        
        return "TBA";
    }
    
    extractTime(dateString:string): string|null {
        const regex = /\d{2}:\d{2}:\d{2}/; // Regex to match the time format (HH:MM:SS)
        const match = dateString.match(regex); // Apply regex to the date string
        return match ? match[0] : null; // Return the time if a match is found
    }
    
}

export default new DateFormatter();
