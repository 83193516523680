<template>
    <portal-layout
    :defaultTitle="false">
        <!-- TODO: search API -->
        <!-- <template #fields>
            <TextInput
            class="w-[310px] mr-5"
            placeholder="Search..." 
            />
        </template> -->
        <template #title>
            <div class="flex items-center justify-between w-full" v-if="!testResultAdvisory">
                <h1 class="mb-5 text-4xl font-bold text-primary-700 lg:mb-0">Your Test Results</h1>
                <div class="relative cursor-pointer" @click.prevent="router.push('/notifications')">
                    <BellIcon class="w-7 h-7" />
                    <div class="absolute top-0 right-0 w-4 h-4 bg-red-700 border-4 border-white rounded-full has-notif"></div>
                </div>
            </div>
        </template>
        <template v-if="!testResultAdvisory">
            <p class="mb-5 text-neutral-700">
                You have 
                <strong class="text-primary-500">
                        {{ newTestResultCount ? newTestResultCount : 0 }} new test {{ newTestResultCount > 0 || newTestResultCount === 'undefined' ? 'results' : 'result' }} 
                </strong>
            </p>
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6" v-if="testResults.length > 0">
                <div v-for="(item, index) in testResults" :key="index">
                    <!-- @click="router.push(`/appointment/show/${testResult.booking_id}?laboratory_id=${testResult.laboratory_id}`)" -->
                    <TestResultCard
                        @click.prevent="viewTestResult(item.booking_id)"
                        :item="item"
                        :badge="false"
                    />
                </div>
            </div>
            <div v-else>
                <p>No Available Test Result(s)</p>
            </div>
        </template>
        <template v-else>
            <h1 class="mb-6 text-4xl font-bold text-center text-primary-500 lg:text-5xl">
               ADVISORY
            </h1>

            <h3 class="mb-6 text-2xl font-bold text-center text-black-500 lg:text-2xl">
                We would like to inform you that our Test Results Page is currently underway and will soon be available on our patient portal.<br>
                With this feature, you can get convenient access to your test results and receive any health information you may need with ease.<br>
                Stay tuned for further updates, and thank you for your patience and understanding.
            </h3>
        </template>
        <!-- <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
            <div class=""
                v-for="result in testResults"
                :key="result"
            >
                <TestResultCard 
                    @click="router.push(`/appointment/show/${result.appointment.booking_id}?laboratory_id=${result.test_result.laboratory_id}`)"
                    :item="result"
                    :badge="checkReadStatus(result.test_result)"
                />
            </div>
        </div> -->
        <!-- TODO: pagination results from API -->
        <!-- <div  class="flex items-center justify-center mt-10" v-if="testResults">
            <template v-if="testResults.length = 12">
                <ChevronLeftIcon 
                    @click="previousPage(page)" 
                    class="w-5 h-5 m-0 cursor-pointer" 
                    :class="[ page == 1 ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                />
                <paginate
                :page-count="testResults.length"
                :container-class="'flex items-center justify-center mx-2 pagination'"
                :page-class="'frm-paginate-btn mx-2 rounded-full text-primary-200 cursor-pointer hover:bg-neutral-200 transition'"
                :active-class="'bg-primary-500 text-white font-bold'"
                :hide-prev-next="true"
                prev-text=""
                next-text=""
                />
                <ChevronRightIcon 
                    @click="nextPage(page)"
                    class="w-5 h-5 m-0 cursor-pointer"
                    :class="[ page == pages?.length ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                />
            </template>
        </div> -->
        <PageLoader v-if="loader" />
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import TestResultCard from './TestResultCard.vue';
// import TextInput from '@/components/inputs/TextInput.vue';
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
// import Paginate from 'vuejs-paginate-next';
import PageLoader from "@/components/PageLoader.vue";

import { useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import { BellIcon } from '@heroicons/vue/24/outline';

import patient from "@/classes/UserData";

const router = useRouter();
const loader = ref(false)
const testResult = ref(); //file
const testResults = ref<any[]>([]); //data
const newTestResultCount = ref();
const patientData = computed(() => patient.getCurrentUser())

const pageNumber = ref("1");
const numberOfBookings = ref("9999");
// const testResultStatus = ref([]);

const fetchData = () => {
    loader.value = true
    //balican
    patient.getTestResults(patientData.value.patient_id, pageNumber.value, numberOfBookings.value)
        .then(({data}) => {
            loader.value = false
            
            if (data?.results?.response) {
                testResults.value = data?.results?.response;
                newTestResultCount.value = testResults.value ? testResults.value.length : 0
            } else {
                testResults.value = [];
                 newTestResultCount.value = 0
            }
           
            // setTimeout(() => {
            //     getSelectedTestResults(testResults.value.laboratory_id)
            // }, 100);

            // testResults.value = result.data.results
            // newTestResultCount.value = testResults.value ? testResults.value.length : 0
            // testResultStatus.value = result.data.testResultStatus;
        })
        .catch((err) => {
            loader.value = false
            console.log(err);
            // if(err.response.status == 401) {
            //     window.location.replace('/login')
            // }
        })
}

// const checkReadStatus = (result) => {
//     return testResultStatus.value.find(x => x.laboratory_id == result.laboratory_id).read_at == null;
// }

// const viewTestResult = () => {
//     if(testResult.value.lab_result_base_64) {
//         const decodedString = atob(testResult.value.lab_result_base_64);
//         const uint8Array = Uint8Array.from([...decodedString].map(char => char.charCodeAt(0)));
//         const blob = new Blob([uint8Array], { type: 'application/pdf' });
//         const testResultFile = URL.createObjectURL(blob);

//         const link = document.createElement('a');
//         link.href = testResultFile;
//         link.target = '_blank';
//         link.click();
//     }

// }
const viewTestResult = (bookingId:number):void => {
    router.replace({ name: 'AppointmentShow', params: { id: bookingId } })
}

const getSelectedTestResults = (laboratoryId: any) => {
    loader.value = true
    
    patient.getSelectedTestResults(laboratoryId)
        .then((result: any) => {
            loader.value = false;
            testResult.value = result.data.response;
        })
        .catch((err) => {
            loader.value = false;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const testResultAdvisory = computed(() => {
    return 'VUE_APP_MODE' in process.env && process.env.VUE_APP_MODE == 'test_results';
})

onMounted( () => fetchData())

//  Paginate Products

// const page = ref(1);
// const pages = ref([]);

// const paginate =(pageNumber)=> {
//     page.value = pageNumber;
// }

// const previousPage = (currentPage = 1) => {
//     if (currentPage > 1) {
//         resetPages();
//         page.value = currentPage - 1;
//     }                     
// }

// const nextPage = (currentPage = 1) => {
//     if (currentPage < pages.value.length) {
//         resetPages();
//         page.value = currentPage + 1;
//     }            
// }

// const resetPages = () => {
//     pages.value = [];
// }
</script>